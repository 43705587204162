<template>
  <b-card no-body>
    <HTMLTitle :title="$t('navigation.menu.meli.analytics.charts')"></HTMLTitle>

    <card-header :title="$t('navigation.menu.meli.analytics.charts')" :icon="['fas', 'chart-line']"></card-header>

    <b-card-body>
      <not-migrated></not-migrated>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import NotMigrated from '@/views/pages/NotMigrated.vue'

export default {
  components: {
    BCard,
    BCardBody,
    HTMLTitle,
    CardHeader,
    NotMigrated,
  },
}
</script>
